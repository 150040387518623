@import "../../variables";

.design {
    min-height: 85vh;
    margin: 0 auto;
    padding: 7rem 0;
    display: flex;
    flex-direction: column;


    &__story {
        text-align: justify;
    }


    
    &__name {
        font-weight: bold;
        line-height: 1.2;
    }

    
    &__story {
        color: $faded-black;
    }

    // .services__site-path {
    //     margin-bottom: 1rem;
    // }

    &__story {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__gallery {
        width: 100%;
        text-align: end;
        display: flex;
        flex-direction: column;

    }

    &__inner-gallery {
        position: relative;
        align-self: flex-end;
        
        overflow: hidden;
    }


    .slick-list {
        overflow: visible;
        margin: 0 auto;
    }


.slick-track {
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    margin-top: 3em;
    margin-bottom: 3em;
}

.slick-active {
    margin: 0 auto;
    transition: opacity 400ms;
}

.slick-slide:not(.slick-active) {
    opacity: 0.2;
}


&__gallery-item {
    
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
    background-size: cover;
    background-position: center;
}

&__learn-more {
    display: flex;
    align-items: center;
      
    .lnr.lnr-arrow-right {
    display: inline-block;
    transform: rotate(-45deg);
}
}


}

@media(min-width: 1500px) {
    .design {
        max-width: 1000px;
    }
}


@media(min-width: 1024px) {
    .design {
        max-width: 800px;
    }

    .design__services {
        font-size: 14px;
    }

    .design__name {
        font-size: 60px;
    }

    .design__story, 
    .design__learn-more {
        font-size: 16px;
    }

    .design__inner-gallery {
        max-width: 60%;
        height: 100%;
    }

    .design__gallery-item {
        min-height: 400px;
    }

    .design__story {
        max-width: 50%;
    }

    .design__slides-num {
        display: none;
    }
}

@media(max-width: 1023px) {
    .design {
        width: 80%;
    }

    .design__services {
        font-size: 12px;
    }

    .design__name {
        font-size: 24px;
    }

    .design__story, 
    .design__learn-more {
        font-size: 14px;
    }

    .design__inner-gallery {
        max-width: 60%;
    }

    .design__gallery-item {
        min-height: 300px;
    }

    .design__story {
        max-width: 50%;
    }

    .design__slides-num {
        text-align: left;
        font-size: 14px;
        color: $faded-black;
    }

}

@media(max-width: 900px) {
    .design__inner-gallery {
        max-width: 70%;
    }

    .design__story {
        max-width: 60%;
    }
}

@media(max-width: 700px) {
    .design__inner-gallery {
        max-width: 90%;
    }
    
    .design__story {
        max-width: 70%;
    }
}

@media (min-width: 501px) {
    .design__inner-gallery {
        padding: 0 10% 50px 10%;
    }
}

@media(max-width: 500px) {
    .design__inner-gallery {
        max-width: 100%;
        padding: 0 20% 0 0%;
    }

    .design__story {
        max-width: 100%
    }

}