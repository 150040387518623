.box {
    width: 1000%;
    margin: 0;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

        .banner {
            min-width: 100%;
            display: flex;
            overflow-x: auto;
            
            &--content {
                font-size: 4.375rem;
                font-weight: bold;
                min-width: 630px;
                margin: .7rem;
                white-space: nowrap;
            }
        }
        .banner::-webkit-scrollbar {
            display: none;
          }

}



