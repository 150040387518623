@import "../../../variables";

.related__inner-productspage {
overflow: hidden;

    .related__item {
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
         -ms-animation: fadein 2s; /* Internet Explorer */
          -o-animation: fadein 2s; /* Opera < 12.1 */
             animation: fadein 2s;
    }
}

.related__productspage {
    padding-top: 0;
    padding-bottom: 0;
}

.product-invisible {
    pointer-events: none;
    height: 0;
}

.products-gallery__noresults {
    margin: 0 auto;
    font-size: 18px;
    color: $faded-black;
}

@keyframes fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
