@import "../../variables";

.aboutus {
    height: auto;
    min-height: 90vh;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    max-width: 1000px;

    .arrow-magnetic-button {
        width: 100px;
        height: 100px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        line-height: 1.8;
    }

    &__story {
        max-width: 628px;
        align-self: flex-end;
    }
    &__story,
    &__story > p,
    &__story > p > span {
        color: $faded-black !important;
    }

    &__name {
        font-weight: bold;
        line-height: normal;
        margin-bottom: 2rem;
        display: flex;
    }

    // .aboutus__site-path {
    //     margin-bottom: 2rem;
    // }

    &__story {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__gallery {
        width: 100%;
        // max-width: ;
        text-align: end;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__inner-gallery {
        position: relative;
        align-self: flex-end;
        width: 100%;
        // overflow: visible;
    }

    .slick-list {
        margin: 0 auto;
    }

    .slick-track {
        display: flex !important;
        align-items: center;
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .slick-slide {
        margin-right: 5px;
    }

    &__gallery-item {
        width: auto;
        margin-left: 0;
        margin-right: 5px;
        background-size: cover;
        background-position: center;
    }

    &__slides-num {
        margin-left: 0.5rem;
    }
}

@media (min-width: 992.99px) {
    .aboutus {
        max-width: 800px;
        margin: 0 auto;

        .aboutus__back-btn {
            display: flex;
            transform: rotate(180deg);
            justify-content: flex-end;
            align-items: center;
            padding-left: 60px;
        }

        .aboutus__services {
            font-size: 14px;
        }

        .aboutus__name {
            font-size: 56px;
            margin-left: -70px;
        }

        .aboutus__story {
            font-size: 16px;
            max-width: 80%;
        }

        .aboutus__inner-gallery {
            height: 100%;
        }

        .aboutus__gallery-item {
            min-height: 400px;
        }

        .aboutus__slides-num {
            display: none;
        }

        /////////////////////////////// changes in slider form
        .slick-active ~ .slick-slide {
            opacity: 0.3;
            width: 128px !important;
            height: 470px !important;
        }

        .slick-active + .slick-slide {
            opacity: 0.8;
        }

        .slick-slide {
            width: 472px !important;
            height: 470px !important;
            transition: opacity 400ms, width 400ms;
        }
    }
}

@media (max-width: 991.99px) {
    .aboutus {
        width: 80%;
        margin: 0 auto;

        &__services {
            font-size: 12px;
        }

        &__name {
            font-size: 30px;
        }

        &__story {
            font-size: 14px;
            max-width: 80%;
        }

        &__gallery-item {
            min-height: 300px;
        }

        &__slides-num {
            text-align: left;
            font-size: 14px;
            color: $faded-black;
        }

        &__back-btn {
            display: none;
        }

        &__inner-gallery {
            padding: 0 16% 0 0%;
        }

        ////////////mobile slider
        .slick-track {
            justify-content: space-around;
        }

        .slick-active {
            // margin: 0 auto;
            transition: opacity 400ms;
        }

        .slick-slide,
        .slick-active {
            width: 300px;
            height: 300px;
        }

        .slick-slide {
            margin-right: 3px;
        }

        .slick-slide:not(.slick-active) {
            opacity: 0.38;
        }

        .slick-list {
            overflow: visible;
        }

        &__gallery {
            max-width: 386px;
        }
    }
}

// @media (max-width: 900px) {
//     // .aboutus__inner-gallery {
//     //     max-width: 70%;
//     // }
// }

@media (min-width: 900px) {
    .aboutus__inner-gallery {
        width: 738px;
    }
}

@media (max-width: 700px) {
    .aboutus {
        &__name {
            font-size: 20px;
        }

        &__story {
            max-width: 90%;
        }
        //////
        .slick-slide {
            width: 290px;
        }

        &__gallery {
            max-width: 376px;
        }
    }
}

@media (max-width: 500px) {
    .aboutus {
        width: 100%;
        &__text {
            margin: 0 30px;
        }

        &__gallery {
            max-width: 340px;
            margin-left: 30px;
        }
    }

    .aboutus__story {
        max-width: 100%;
    }
}

@media (max-width: 380px) {
    .aboutus {
        &__gallery {
            max-width: 320px;
        }
    }
}

@media (max-width: 350px) {
    .aboutus {
        &__gallery {
            max-width: 300px;
        }
    }
}

@media (max-width: 325px) {
    .aboutus {
        &__gallery {
            max-width: 280px;
        }
    }
}

@media (min-width: 386px) {
    .aboutus {
        &__gallery {
            margin: 0 auto;
        }
    }
}
