@import "../../../variables";

.products__filter {
    margin: 0.5rem 20px 3rem 20px;

    //SINGLE FILTER BOX

    label {
        padding: 5px;
        padding-top: 0;
    }

    .products__upper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1rem;
        margin-bottom: 0;
        padding-bottom: 1rem;

        span {
            align-self: center;
        }
    }

    .products__upper,
    .products__filter-list {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .products__text {
        line-height: 1.5;
        max-width: 90%;

        a {
            text-decoration: none;
            color: $black;
        }

        a:hover {
            text-decoration: none;
            color: $light-grey;
        }
    }

    //PLUS-MINUS TRANSFORMATION ANIMATION ON FILTER BOXES

    .products__icons {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.5rem;
        width: 1.5rem;
        margin-top: 1rem;

        span {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
        }

        .las.la-plus,
        .las.la-minus {
            font-size: 24px;
        }

        .icon-default {
            transition: opacity 0.3s, transform 0.3s;
        }

        .icon-focus {
            transition: opacity 0.3s, transform 0.3s;
            transform: rotate(-180deg) scale(0.5);
            opacity: 0;
        }

        &.focused {
            transform: scale(1.2) ease-in;

            .icon-focus {
                transform: rotate(0deg) scale(1);
                opacity: 1;
            }
            .icon-default {
                transform: rotate(180deg) scale(0.5);
                opacity: 0;
            }
        }
    }

    //FILTER LISTS

    .products__filter-list {
        height: 0;
        overflow: hidden;
        border-top: none;
        transition: height 500ms, padding-top 400ms ease-out, padding-bottom 400ms ease-out, border-top 800ms ease-out;
    }

    .list-open {
        height: auto;
        padding-top: 1rem;
        border-top: 1px solid $light-grey;
    }

    .products__filter-inputwrapper {
        display: flex;
        height: auto;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: $white-smoke;
            border-radius: 20px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $light-grey;
            border-radius: 20px;
            border: 2px solid $white-smoke;
        }
    }

    .products__filter-inputs > div > label {
        font-size: 14px;
    }

    .checkbox__input {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

    .clear-button {
        display: flex;
        justify-content: flex-end;

        span:first-child {
            color: $faded-black;

            &:hover {
                color: $light-grey;
                font-weight: 700;
            }
        }
    }

    .search-span-icon {
        display: flex;
        align-items: center;
        &:hover,
        &:hover > span {
            color: $light-grey;
            font-weight: 700;
        }

        span {
            margin-left: 5px;
        }
    }

    .filter-viewall {
        &:hover {
            color: $light-grey;
        }
    }
}

@media (min-width: 1024px) {
    .products__filter > form,
    .clear-button {
        width: 40rem;
        margin: 0 auto;
    }

    .products__filter > form {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .clear-button {
        margin-top: 1rem;
        text-align: right;
        font-size: 14px;
    }

    .products__filter-inner {
        position: relative;
        width: 33%;
        border: none;

        &:first-child,
        &:nth-child(2) {
            border-right: 1px solid $light-grey;
        }
    }

    .products__name {
        font-size: 24px;
    }

    .products__viewall,
    .products__selected {
        font-size: 14px;
    }

    .products__filter-list {
        position: absolute;
        bottom: 100%;
        width: 300%;
        background-color: white;

        * {
            height: 0;
            transition: height 1000ms linear;
        }
    }

    .products.products__filter-list {
        left: -1rem;
    }

    .brand.products__filter-list {
        left: calc(-100% - 1rem);
    }

    .designer.products__filter-list {
        left: calc(-188% - 2.8rem);
    }

    .list-open {
        z-index: 201;
        padding: 1rem 1rem 70px 1rem;
        height: auto;

        * {
            height: auto;
        }
    }

    .products__filter-inputwrapper {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        // overflow-x: hidden;
        // overflow-y: scroll;
    }

    .products__filter-inputwrapper > div {
        flex-grow: 1;
        width: 20%;
    }

    .checkbox__input > span > input {
        visibility: hidden;
    }
}

@media (max-width: 1023px) {
    .products__filter-inner {
        margin-top: 1rem;
        border: 1px solid $light-grey;
    }

    .products__filter > form,
    .clear-button {
        max-width: 25rem;
        margin: 0 auto;
    }

    .clear-button {
        margin-top: 1rem;
        text-align: right;
        font-size: 12px;
    }

    .products__name {
        font-size: 12px;
    }

    .product__viewall,
    .products__selected {
        font-size: 14px;
        font-weight: normal;
    }

    .list-open {
        height: auto;
        padding-bottom: 1rem;
    }

    .products__filter-inputwrapper {
        min-height: 500px;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

@media (min-width: 500px) {
    .products__filter-inputwrapper {
        div {
            margin-right: 2rem;
        }
    }
}
