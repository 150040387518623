.related {
    margin: 0 auto;
    overflow-x: hidden;
    padding-left: 2em;
    padding-right: 2em;
    
    display: flex;
    flex-direction: column;

    &__inner {
        margin-right: auto;
        margin-left: auto;
        display: flex;
        max-width: 980px;
    }


    &__item {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    a, a:hover {
        text-decoration: none;
        color: black;
    }

    &__viewmore {
        display: flex;
        align-self: center;
        margin-bottom: 50px;
    }

    &__viewmore-inner {
        display: flex;
        align-items: center;
         span {
             margin-left: 5px;
         }
    }

    
    &__image {
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__item:nth-child(2) {
        order: 3;
    }
    
    &__item:nth-child(3) {
        order: 2;
    }
    
    &__info {
        font-weight: bold;
    }

    &__add {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        max-height: 100%;
        align-self: center;
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;

        span {
            margin-left: 5px;
        }
    }

    &__text {
        margin-top: .7em;
        bottom: 0;
    }
}


@media (min-width: 992px) {
    .related {
        &__inner {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__item {
            margin-top: 20px;
        }

        &__item:nth-child(2) {
            order: 2;
        }

        &__item:nth-child(3) {
            order: 3;
        }


        &__item:first-child > a > div.related__image {
            width: 380px;
            height: 470px;
        }
        
        &__item:nth-child(2) > a > div.related__image {
            width: 475px;
            height: 470px;
            
        }

        &__item:nth-child(3) > a > div.related__image {
            width: 980px;
            height: 497px;
            
        }

        
        

        

        &__add {
            justify-content: flex-start;
        }

        &__link {
            display: none;
        }
    }
}

@media (max-width: 991.99px) {
    .related {
        &__inner {
            max-width: 500px;
            flex-direction: column;
        }

        &__item {
            margin-top: 50px;
        }


        &__item:first-child > a > div.related__image,
        &__item:nth-child(2) > a > div.related__image {
            height: 305px;
        }

        &__item:first-child,
        &__item:nth-child(2) {
            width: 250px;
            
        }

        &__item:nth-child(3) {
            width: 302px;
            
            .related__image {
                height: 219px;
            }
        }

        &__item:first-child {
            align-self: flex-end;
        }

    }
}


@media (max-width: 450px) {
    .products__contents {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}


@media (min-width: 992px) {
    .related {
        padding-top: 100px;
        padding-bottom: 100px;

        &__viewmore-inner,
        &__info {
            font-size: 16px;
        }
        
        &__add {
            font-size: 14px;
        }
    }
}

@media (max-width: 992px) {
    .related {
        padding-top: 20px;
        padding-bottom: 20px;
        
        &__viewmore-inner,
        &__info {
            font-size: 14px;
        }

        &__add {
            font-size: 12px;
        }
    }
}



@media (min-width: 576px) {
    .related__item {
        &__image {
            min-height: 15em;
        }
    }
}
