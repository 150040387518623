@import "../../../variables";

.overlay-topbar {
    position: fixed;
    top: 0;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 40px 1.5em;
    z-index: 100;
    transition: top .5s;
    transition: opacity .3s linear;

    &__logo {
        width: 98px;
        height: 46.49px;
        
    }

    &__burger {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 29.7px;
        height: 14px;
    }

    .burger-container {
        height: 100%;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__shop {
        width: 35px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: $white;

        a, a:hover {
            text-decoration: none;
            color: $white;
        }


    }

    @media (min-width: 992px) {
        .burger-container {
            width: 7rem;
        }
    }

}

.topbar-hide{
    top:-90px
}

    
.overlay-topbar-hidden {
    opacity: 0;
    pointer-events: none;
}