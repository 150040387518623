@import "../../variables";

.contact-page {
  height: auto;
  display: flex;
  position: relative;
  margin: 0 auto;
  padding: 100px 0;
  margin-bottom: 3em;

  //GET-IN-TOUCH WITH TEXT OVERLAY
  &__img {
    display: flex;
    align-items: center;
    

    

    div.get-in-touch {
      position: relative;
      

      h3.touch {
        
        text-align: center;
        top: 0;
      }
    }

    

    h3.get-in {
      
      position: relative;
      top: 26px;
      color: white;
      
    }

    h3.get-in:before {
      content: attr(data-content);
      position: absolute;
      left: 0;
      top: 0;
      color: black;
      
    }
  } 
  
  &__img-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  //END OF GET-IN-TOUCH WITH TEXT OVERLAY




  //CONTACT INFO COLUMNS
  &__container {
    margin-bottom: 20px;
  }
  &__text {
    display: flex;
    color: $faded-black;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 90%;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  &__labels {
    color: $light-grey;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__media {
    a {
      color: $black;
      text-decoration: none;
    }
    a:hover {
      color: $light-grey;
    }
  }

  &__media-wrapper {
    max-width: 200px;
    display: flex;
    justify-content: space-between;
  }

  &__address-first,
  &__address-second,
  &__email {
    margin-bottom: 30px;
  }

  //END OF CONTACT INFO COLUMNS




  //EMAIL INPUT
  &__email-wrapper {
    // width: 100%;
    box-shadow: white;
    // border: 1px solid black;
  }

  &__input-data {
    height: 40px;
    width: 100%;
    position: relative;
    display: flex;
  }
  &__input-data input {
    background-color: white;
    height: 100%;
    width: 100%;
    border: none;
    font-size: 12pt;
    border-bottom: 2px solid $black;
    display: flex;
    align-items: center;
    caret-color: $black;
    color: $black;
  }
  &__input-data input:focus ~ label,
  &__input-data input:hover ~ label,
  input.dirty ~ label
  // &__input-data input:valid ~ label 
  {
    transform: translateY(-25px);
    font-size: 10pt;
    color: $black;
  }

  &__input-data input:focus {
    outline: none;
  }

  &__input-data label {
    position: absolute;
    bottom: 1px;
    left: 0;
    color: $black;
    pointer-events: none;
    transition: all 0.3s ease;
  }

  

  &__email-underline {
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
  }

  &__input-data span {
    align-self: center;
    font-weight: bold;
  }
  &__email-underline:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: $black;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
  }
  &__input-data input:focus ~ &__email-underline:before
  // &__input-data input:valid ~ &__email-underline:before 
  {
    transform: scaleX(1);
  } 
  
  &__small-underline {
    width: 20px;
    height: 1px;
    background-color: black;
    margin-top: .5em;
    margin-bottom: .5em;
  }//END OF EMAIL INPUT
}


//web

@media (min-width: 992.99px) {
  .contact-page {
    max-width: 1000px;
    position: relative;
    justify-content: center;
    margin-bottom: 600px;
    overflow: visible;
  }

  .contact-page__img-img {
    height: 470px;
    width: 338px;
}

.contact-page__img {
  line-height: 1.2;
}

.contact-page__text {
  position: absolute;
  justify-self: center;
  bottom: -525px;
  left: 537px;
}

.get-in-touch {
  width: 356px;
  top: -114px;
}

.get-in, .touch {
  font-size: 90px;
}


h3.get-in {
  margin-left: -69px;
}

h3.get-in:before {
  clip-path: inset(0 0 0 69px);
}

.contact-page__labels {
  font-size: 16px;
}

.contact-page__media-wrapper,
  .contact-page__address {
    font-size: 20px;
  }

  .contact-page__email-wrapper {
    width: 270.99px;
  }
}


//mobile

@media (max-width: 992px) {

  div.contact-page {
    flex-direction: column;
    max-width: 90%;
    justify-content: space-between;
  }

  .contact-page__img-img {
      height: 228px;
      width: 163px;
  }

  .contact-page__img {
    line-height: 1.5;
  }

  .get-in, .touch {
    font-size: 41px;
  }
  
  h3.get-in {
    margin-left: -31px;
  }

  h3.get-in:before {
    clip-path: inset(0 0 0 31px);
  }

  .get-in-touch {
    top: -50px;
    width: 165px;
  }

  .contact-page__labels {
    font-size: 14px;
  }

  .contact-page__media-wrapper,
  .contact-page__address {
    font-size: 16px;
  }

  .contact-page__email-wrapper {
    width: 269px;
  }
}




@media (min-width: 380px) {
    .contact-page__media-wrapper {
    flex-direction: row;
  }
}

@media (max-width: 320px) {
  .contact-page__email-wrapper {
    width: 230px;
  }
}
