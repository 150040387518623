@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/utilities";
@import "~linearicons/dist/web-font/style.css";
@import "~line-awesome/dist/line-awesome/scss/line-awesome.scss";
@import "./variables";

@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/fonts/boldHelveticaNeueLTGEOW82-75Bd.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/fonts/regularHelveticaNeueLTGEOW82-55Rm.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "HelveticaNeue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    width: 20px;
    cursor: auto !important;
  }
  &::-webkit-scrollbar-track {
    background: $white-smoke;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $faded-black;
    border-radius: 20px;
    border: 5px solid $white-smoke;
  }

  h1 {
    // = 60px
    font-size: 3.75rem;
  }

  h2 {
    //50px
    font-size: 3.125rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  .medium-text {
    //= 12px
    font-size: 0.75rem;
  }

  .bold {
    font-weight: bold;
  }

  a,
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }

  .site-path {
    margin-bottom: 12px;
    font-size: 12px;
    a,
    a:hover,
    a:visited,
    a:link,
    a:active {
      text-decoration: none;
      color: $black;
    }
  }

  .magnetic-button {
    display: block;
    width: 76.5px;
    height: 76.5px;
    background-image: url(/assets/components/cursor-next.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    svg {
      width: 76.5px;
      height: 76.5px;
    }
  }

  svg,
  path {
    shape-rendering: geometricPrecision;
  }

  div.fullscreen {
    cursor: none !important;
  }

  .arrow-magnetic-button {
    min-width: 80px;
    min-height: 80px;
    // border: 1px solid black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      // border: 1px solid black;
      position: absolute;
      transition: opacity 0.2s linear;
    }

    &:hover > svg {
      opacity: 0;
    }
    span {
      color: black;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .slider-prev-button {
    transform: rotate(180deg);
  }
}

#Component_14_1 {
  pointer-events: none;
}

.slick-list,
.slick-track {
  touch-action: pan-y;
}

@media (min-width: 1024px) {
  .site-path {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .site-path {
    display: none;
  }

  div.slider-prev-next-buttons {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    justify-content: space-between;
  }
}

@media (max-width: 992px) {
  .slider-prev-next-buttons {
    display: none;
  }
}
