@import "../../../variables";

.events {
    margin: 20px auto 100px auto;

    a, a:hover, a:focus, :active {
        text-decoration: none;
        color: $black;
    }

    &__main {
        position: relative;
    }

    &__heading {
        font-weight: bold;
    }

    div {
        transition: color 0.1s;
    }

    &__detail {
        border-bottom: 1px solid;
        display: flex;
        // max-width: 98%;
    }

    &__detail.first {
        border-top: 1px solid;
    }

    &__date {
        min-width: fit-content;
        font-size: 0.75rem;
        margin-left: 0;
        margin-right: auto;
        top: 0;
    }

    &__text {
        font-size: 0.875rem;
        line-height: normal;
        margin: 0 1em;
        min-width: 60%;

        a {
            color: $black;
        }

        a:hover,
        a:focus {
            text-decoration: none;
        }

        section {
            text-align: left;
            margin-top: 1em;
            font-size: 0.875rem;
        }
    }

    &__name {
        font-size: 1.25rem;
        font-weight: bold;
    }

    &__thumbnail {
        display: none;
    }
}

.events__main:hover > a > div {
    color: $light-grey;
}

.events__main:hover > a > .events__detail.first { //underline under heading to always be black
    border-top: 1px solid $black;
}


.events__main > a > div.events__detail:hover {
    color: $black;
}


@media (max-width: 1200px) {
    .events {
        &__date {
            font-size: 0.75rem;
        }
    }
}

@media (min-width: 1200px) {
    .events {
        
        &__date {
            font-size: 0.875rem;
        }

        &__text {
            padding-left: 6em;
        }

        &__name {
            font-size: 1.875rem;
        }
    }

    .events__thumbnail {
        display: inline-block;
        // right: 0;
        top: 2em;
        // min-height: 120%;///////////////////////////////
        // max-width: 30%;
        position: absolute;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media (min-width: 1800px) {
    .events {
        max-width: 1600px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media(max-width: 1799.99px) {
    .events {
        margin-left: 100px;
        margin-right: 100px;
    }
}

@media (min-width: 1220px) {
    .events {
        &__text {
            section {
                max-width: 50%;
            }
        }
    }
    // .events__thumbnail {
    //     max-height: 12em;/////////////////
    // }
}

@media (min-width: 1024px) {
    
    .events__thumbnail {
        display: inline-block;
        min-height: 15em;
        max-width: 30%;
        right: 0;
        top: 2em;
        // width: 30%;
        position: absolute;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media (min-width: 992px) {
    .events {
        &__detail {
            padding: 45px 0;
        }

        &__heading {
            font-size: 60px;
            margin-bottom: 50px;
        }

        &__text {
            padding-left: 6em;
            width: 82%;

            section {
                max-width: 60%;
            }
        }

        
    }     
}

@media (max-width: 992px) {
    .events {
        margin-left: 50px;
        margin-right: 50px;

        &__heading {
            font-size: 45px;
            margin-bottom: 25px;
        }

        &__detail {
            padding: 30px 0;
        }

        &__text {
            width: 80%;
        }
    }
}


@media (min-width: 768px) {
    .events {
        &__text {
            section {
                max-width: 60%;
            }
        }
    }

}

@media (min-width: 768px) {
    .events {
        &__thumbnail {
            width: 368px;
            height: 368px;
        }
    }
}

@media (max-width: 768px) {
    .events {
        margin-left: 30px;
        margin-right: 30px;

        &__text {
            padding-left: 3em;
        }
    }

    .events__thumbnail {
        display: none;
    }
}

@media (min-width: 576px) {
    .events {
        &__text {
            margin: 0 1em 0 2em;
        }
    }
}

@media (max-width: 576px) {
    .events {
        &__text {
            padding-left: 0;
            
        }

        
        &__heading {
            font-size: 36px;
        }

       
    }
}
