@import "../../../variables";

.slider {
    width: 100vw;
    // height: 0;

    height: 100%;
    visibility: hidden;
    clip-path: none;
    transition: polygons-closed 1s linear;
    background-color: $white;
    color: $black;
    z-index: 200;
    position: fixed;
    overflow: hidden;

    &__contents {
        display: flex;
        height: 100%;
        margin: 0 15px;
    }

    &__close-btn {
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lnr-cross {
        height: auto;
    }

    &__central {
        width: 90%;
        max-width: 1500px;
        margin: 0 auto;
        display: flex;
    }

    &__main {
        display: block;
        margin-left: 15rem;
        height: auto;
        color: $white;

        div,
        a {
            color: $black;
        }
    }

    &__item {
        display: flex;
        position: relative;
        width: 23rem;
        margin-top: 0.5rem;
    }

    &__subs-container {
        position: absolute;
        top: 0;
        left: 100%;
        min-width: 26rem;
    }

    &__item-sub {
        display: flex;
        flex-wrap: nowrap;
    }

    .contact {
        margin-top: 0.8rem;
    }

    .contact-info {
        margin-top: 6rem;
        width: 168px;
    }

    &__item-name {
        white-space: nowrap;
        font-size: 45px;
        font-weight: bold;
        transition: margin-left 300ms ease-out;
    }

    &__item-sub {
        font-size: 35px;
    }
}

.menu-open {
    visibility: visible;
    animation: polygons 0.7s linear;
}

.menu-close-animation {
    visibility: visible;
    animation: polygons-closed 0.7s linear;
}

.hidden {
    display: none;
}

.lang {
    justify-self: end;
    display: flex;
    color: $light-grey;

    div {
        margin: 0.3rem;
    }
}

.lang:last-child {
    bottom: 0;
    right: 0;
}

.contact-info {
    margin-left: auto;
    margin-right: 10rem;
    font-size: 14px;
    div {
        font-size: 14px;
    }
    .logoMenu {
        min-width: 115px;
        height: 54px;
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 0.5rem;
    }
    .contactMenu {
        margin-top: 2rem;
    }
}

.contact__small-underline-menu {
    background-color: $black !important;
}

.highlighted {
    color: $black;
}

@keyframes slide {
    to {
        transform: translateX(2rem);
    }
}

@keyframes polygons {
    0% {
        clip-path: polygon(0% 0px, 0px 0px, 0px 100%, 0% 100%);
    }

    50% {
        clip-path: polygon(70% 0px, 0px 0px, 0px 100%, 30% 100%);
    }

    100% {
        clip-path: polygon(100% 0px, 0px 0px, 0px 100%, 100% 100%);
    }
}

@keyframes polygons-closed {
    100% {
        clip-path: polygon(0% 0px, 0px 0px, 0px 100%, 0% 100%);
    }
    60% {
        clip-path: polygon(0% 0px, 0px 0px, 0px 100%, 25% 100%);
    }
    20% {
        clip-path: polygon(75% 0px, 0px 0px, 0px 100%, 100% 100%);
    }
    0% {
        clip-path: polygon(100% 0px, 0px 0px, 0px 100%, 100% 100%);
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (min-width: 1601px) {
    .lnr-cross {
        width: 2rem;
        font-size: 32px;
    }
}

@media (max-width: 1600px) {
    .slider__main {
        margin-left: 10rem;
    }

    .slider__item {
        max-width: 20rem;
    }

    .lnr-cross {
        width: 1.5rem;
        font-size: 25px;
    }

    .slider__item-name {
        font-size: 38px;
    }

    .slider__item-sub {
        font-size: 28px;
    }
}

@media (max-width: 1400px) {
    .slider__main {
        margin-left: 8rem;
    }

    .slider__item {
        width: 18rem;
    }

    .slider__item-name {
        font-size: 35px;
    }

    .slider__item-sub {
        font-size: 25px;
    }
}

@media (max-width: 1400px) {
    .slider__item {
        width: 18rem;
    }
}

@media (max-width: 1300px) {
    .slider__main {
        margin-left: 5rem;
    }
    .contact-info {
        margin-right: 5rem;
    }
}

@media (max-width: 1200px) {
    .slider__main {
        margin-left: 3rem;
    }
    .contact-info {
        margin-right: 5rem;
    }
}

@media (max-width: 1100px) {
    .slider__main {
        margin-left: 1rem;
    }
    .contact-info {
        margin-right: 1rem;
    }
}

@media (min-width: 1024px) {
    .slider__subs-container {
        padding-left: 2rem;
    }

    .slider__main {
        div {
            color: $black;
        }
    }

    .slider__item {
        &:hover > .slider__subs-container {
            display: block !important;
            height: 15rem;
            -webkit-animation: fadeIn 1s;
            animation: fadeIn 1s;
        }
    }

    .slider {
        &__main:hover > .slider__item > .slider__item-name,
        &__main:hover > .slider__item > .slider__item-name > a,
        &__subs-container:hover > .slider__item-sub,
        &__subs-container:hover > .slider__item-sub > a {
            color: $light-grey;
        }

        &__main > .slider__item:hover > .slider__item-name,
        &__main > .slider__item:hover > .slider__item-name > a,
        &__subs-container > .slider__item-sub:hover,
        &__subs-container > .slider__item-sub:hover > a {
            color: $black;
        }

        &__main > .slider__item:hover > .slider__item-name,
        &__main > .slider__item:hover > .slider__item-name > a,
        &__subs-container > .slider__item-sub:hover > a {
            animation: slide 200ms linear;
            animation-fill-mode: forwards;
        }
    }
}

@media (max-width: 1023.99px) {
    .slider__item {
        flex-direction: column;

        .slider__subs-container {
            display: block;
            height: 0;
            overflow: hidden;
        }
    }


    .slider__item-touched {
        .slider__subs-container {
            display: block;
            height: 100%;
            -webkit-animation: fadeIn 1s;
            animation: fadeIn 1s;

            .slider__item-sub:hover,
            .slider__item-sub:hover > a {
                color: $black !important;
                animation: none !important;
            }
        }

        .slider__item-name,
        .slider__item-name > a {
            color: $black !important;
            animation: none !important;
        }
    }

    .contactMenu,
    .logoMenu {
        display: none;
    }

    .contact-info {
        align-self: flex-end;
    }

    .slider__main {
        margin: 0 auto;
        padding-left: 10%;
        div {
            color: $black;
        }
    }

    .slider__item-name,
    .slider__item-sub,
    .slider__item-name > a,
    .slider__item-sub > a {
        color: $black;
    }

    .slider__item-sub {
        margin-top: 0.5rem;
        line-height: 1.2;
    }

    .slider__subs-container {
        position: relative;
        height: 0;
        left: 0;
        border-left: 1px solid $light-grey;
        padding-left: 1rem;
    }

    .slider__main:hover > .slider__item > .slider__item-name,
    .slider__main:hover > .slider__item > .slider__item-name > a,
    .slider__subs-container:hover > .slider__item-sub,
    .slider__subs-container:hover > .slider__item-sub > a {
        color: $light-grey;
    }

    .lang {
        justify-content: flex-end;
    }
}

@media (min-width: 1000px) {
    .lang {
        font-size: 30px;
    }
}

@media (min-width: 992px) {
    .slider__close-btn {
        width: 7rem;
    }
    .slider__central {
        margin: 30px auto;
    }
}

@media (max-width: 992px) {
    .slider {
        &__central {
            margin: 30px auto;
        }
        .lang {
            justify-content: flex-end;
        }
        &__close-btn {
            width: 3rem;
        }
    }
}

@media (min-width: 651px) {
    .slider__main {
        margin-top: 6rem;
    }
}

@media (max-width: 650px) {
    .slider__main {
        margin-top: 4rem;
    }
    .slider__central {
        flex-direction: column;
    }

    .contact-info {
        align-self: flex-end;
        margin-top: auto !important;
    }

    .lnr-cross {
        font-size: 20px;
    }

    .slider__item-name {
        font-size: 30px;
    }

    .slider__item-sub {
        font-size: 20px;
    }

    .slider__subs-container {
        min-width: 15rem;
    }
}

@media (max-width: 500px) {
}
