@import "../../variables";

.products {
    display: block;
    margin-bottom: 50px;
    min-height: 90vh;

    &__welcome {
    position: relative;
    padding: 0;
    min-height: 40vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 3rem;
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        opacity: .70;
    }

    &__overlay-text {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 36px;
        font-weight: bold;
    }

    &__contents {
        margin: 1rem 2rem;

    }

    &__site-path {
        margin-left: 20px;
    }

}





@media (min-width: 1024px) {
    .products__welcome {
        height: 390px;
        margin-bottom: 2rem;
    }
}

@media (max-width: 1023px) {
   
}

@media (min-width: 500px) {

}