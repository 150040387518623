@import "../../variables";

//SUBSTITUTING NATIVE CHECKBOXES WITH CUSTOM ONES

//setting grid for checkbox and hiding the native

.checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 14px;
    // color: var(--color);
}

.checkbox__input {
    display: grid;
    grid-template-areas: "checkbox";

    > * {
        grid-area: checkbox;
    }
}

.checkbox__control {
    display: inline-grid;
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
    // border: 0.1em solid currentColor;
}

.checkbox__control svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
}

//custom checkboxes from https://codepen.io/aaroniker/pen/PowZbgb

.checkbox {
    --background: #fff;
    --border: #d1d6ee;
    --border-hover: #bbc1e1;
    --border-active: #1e2235;
    --tick: #fff;
    position: relative;
    input,
    svg {
        width: 21px;
        height: 21px;
        display: block;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        padding: 0;
        border-radius: 4px;
        transition: box-shadow 0.3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }
        &:checked {
            --b: var(--border-active);
        }
    }
    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.bounce {
        --stroke: var(--tick);
        input {
            &:checked {
                --s: 11px;
                & + svg {
                    animation: bounce 0.4s linear forwards 0.2s;
                }
            }
        }
        svg {
            --scale: 0;
        }
    }
}
@keyframes bounce {
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

.checkbox__input input:not(:checked) ~ label {
    color: $black;
}
.checkbox__input input:checked ~ label {
    font-weight: bold;
}
//  + .checkbox__control svg {
//   transform: scale(1);
// }
