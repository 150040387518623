.interiors {
    min-height: 100vh;

    &__inner {
        padding-top: 100px;
        margin-bottom: 6rem;
    }

    &__inner-content {
        display: flex;
    }

    &__item {
        min-height: 20rem;
        // max-width: 20rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        
    }

    &__overlay {
        position: absolute;
        top: auto;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: .2;
    }

    &__text {
        position: absolute;
        text-align: left;
        bottom: 1rem;
        left: 1rem;
        color: white;

        &>div:first-child {
            opacity: .6;
            font-size: 16px;
        }

        &>div:last-child {
            font-size: 28px;
        }
    }
}




@media (min-width: 992.99px) {
    .interiors {

        &__inner {
            display: flex;
            justify-content: center;
        }

        &__inner-content {
            max-width: 1200px;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
           justify-content: center;
        }

        &__item {
            width: 100%;
            flex: 0 40%;
            margin: 2rem;
        }
    }
}

@media (max-width: 992px) {
    .interiors {
        margin: 0 1rem;
        &__inner-content {
            flex-direction: column;
            align-items: center;
        }

        &__item {
            width: 20rem;
            margin: 1rem;
        }
    }
}

@media (min-width: 450px) {
    .interiors {
        .site-path {
            margin-left: 10%;
        }
    }
}