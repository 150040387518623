@import "../../../variables";

.projectdetail {
    min-height: 500px;
    color: black;

    &__hatimg,
    &__overlayimg {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__intro {
        padding-top: 4.5rem;
    }

    &__description {
        max-width: 950px;
        margin: 3rem auto;
    }

    &__gallery {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    &__text1,
    &__text2 {
        max-width: 470px;
    }

    &__text1,
    &__text2,
    &__text3 {
        color: $faded-black;
    }

    &__text3 {
        max-width: 850px;
        margin: 3rem auto;
    }

    &__category {
        color: $faded-black;
    }

    &__name {
        font-weight: bold;
        color: $black;
    }

    &__text1-body,
    &__text3 {
        font-size: 16px;
    }

    &__text1-body {
        margin-top: 0.5rem;
    }

    //GALLERY

    &__gallery {
        width: 100%;
        text-align: end;
        display: flex;
        flex-direction: column;
        padding: 2rem 0px;
    }

    &__inner-gallery {
        align-self: flex-start;
        width: 100%;
        overflow: hidden;
    }

    .slick-list {
        overflow: visible;
        margin: 0 auto;
    }

    .slick-track {
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .slick-active {
        margin: 0 auto;
        transition: opacity 400ms;
    }

    .slick-slide:not(.slick-active) {
        opacity: 0.3;
    }

    &__gallery-item {
        width: auto;
        margin-left: 0;
        margin-right: 5px;
        background-size: cover;
        background-position: center;
    }

    &__slides-num {
        margin-left: 0.5rem;
    }

    //grid gallery

    &__innergallery-web {
        display: none;

        @media (min-width: 700px) {
            display: block;
        }
    }

    div.ReactGridGallery_tile-viewport {
        img {
            object-fit: cover;
        }
    }

    &__share {
        margin: 20px 0 40px 0;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        div {
            width: 100px;
            padding: 8px 0;
            text-align: left;
            a {
                display: flex;

                span {
                    align-self: center;
                    display: inline-block;
                    margin-left: 4px;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    //next project

    &__next {
        margin-top: 3rem;
        margin-bottom: 3rem;

        a,
        a:hover {
            text-decoration: none;
            color: $black;
        }
    }

    &__next-title {
    }

    &__next-overlay {
    }

    &__next-overlayimg {
        width: 367px;
        height: 249px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;

        span {
            margin: 30px;
            width: 72.25px;
            height: 72.25px;
            svg {
                path {
                    fill: white;
                }
            }
        }
    }

    &__next-darkoverlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    &__next-web {
        border-top: 1px solid $white-smoke;
        padding-top: 50px;
        position: relative;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;

        a,
        a:hover {
            text-decoration: none;
            color: $black;
        }
    }

    &__intro.next {
        height: 250px;
        padding-top: 0;

        .projectdetail__hatimg {
            margin-top: 0;
        }

        .projectdetail__text1 {
            margin-top: 3rem;
        }
    }

    &__gradient-overlay {
        position: absolute;
        top: auto;
        bottom: 0;
        width: 100%;
        height: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+98&0+0,1+100 */
        background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.98) 98%,
            rgba(255, 255, 255, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.98) 98%,
            rgba(255, 255, 255, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.98) 98%,
            rgba(255, 255, 255, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
}

// LIGHTBOX

#lightboxBackdrop {
    cursor: none !important;
}
#lightboxBackdrop > * {
    pointer-events: auto;
    @media (min-width: 1024px) {
        pointer-events: none;
    }

    button {
        display: block;
        @media (min-width: 1024px) {
            display: none;
        }
    }
}

@media (min-width: 2000px) {
    .projectdetail {
        &__intro {
            max-width: 1200px;
            margin: 0 auto 4.5rem auto;
        }
    }
}

@media (min-width: 1500px) {
    .projectdetail {
        &__hatimg {
            max-height: 800px;
        }
    }
}

@media (min-width: 1024.99px) {
    .projectdetail {
        &__category {
            font-size: 16px;
        }

        &__intro {
            display: flex;
            justify-content: space-between;
        }

        &__description {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            position: relative;
        }

        &__name {
            font-size: 60px;
        }

        &__hatimg {
            height: 50vw;
            max-width: 50%;
            margin-top: 2rem;
            flex-grow: 1;
        }

        &__overlaycontainer {
            max-height: 450px;
        }

        &__overlayimg {
            height: 450px;
            width: 420px;
        }

        &__overlaytext {
            position: relative;
            top: -370px;
            left: 362px;
            color: white;
            margin-left: -70px;
            font-size: 30px;
            max-width: 490px;
        }

        &__overlaytext:before {
            content: attr(data-content);
            position: absolute;
            left: 0;
            top: 0;
            color: black;
            clip-path: inset(0 0 0 127px);
        }

        &__text1 {
            margin: 4rem auto;
            padding-right: 4rem;
        }

        &__text2 {
            font-size: 16px;
            overflow: hidden;
            height: 1%;
            align-self: flex-end;
            margin-right: 1rem;
            margin-bottom: 2rem;
        }

        &__next-title {
            font-size: 16px;
        }

        &__inner-gallery {
            display: none;
        }

        &__next {
            display: none;
        }

        &_next-web {
            ////////////////////////////////////////////////////
            display: block;
        }
    }
}

@media (max-width: 1024px) {
    .projectdetail {
        &__category {
            font-size: 12px;
        }

        &__name {
            font-size: 28px;
        }

        &__text1 {
            margin: 3rem;
        }

        &__text1 {
            margin-bottom: 1rem;
        }

        &__text3 {
            margin-bottom: 0;
            margin-left: 2rem;
            margin-right: 2rem;
            p:last-child {
                margin-bottom: 0;
            }
        }

        &__text2 {
            margin: 0 2rem 3rem 2rem;
            font-size: 14px;
        }

        &__text1-body,
        &__text2,
        &__text3-body {
            line-height: 1.3;
        }

        &__hatimg {
            height: 50vh;
        }

        &__overlayimg {
            height: 35vh;
        }

        &__overlaytext {
            display: none;
        }

        //gallery

        &__gallery-item {
            min-height: 300px;
        }

        &__slides-num {
            text-align: left;
            font-size: 14px;
            color: $faded-black;
        }

        //next project
        &__next {
            display: block; /////////////////////////////////////////////////////
        }

        &__next-web {
            display: none;
        }

        &__next {
            margin-left: 2rem;
        }

        &__next-title {
            font-weight: bold;
            font-size: 28px;
        }

        &__next-overlay {
            display: flex;
        }

        &__next-overlayimg {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &__next-overlaytext {
            position: relative;
            left: -51px;
            top: 50px;
            height: 30px;
        }

        &__next-category {
            font-size: 12px;
            color: $light-grey;
            white-space: nowrap;
        }

        &__next-overlayname {
            font-size: 29px;
            position: relative;
            color: white;
        }

        &__next-overlayname:before {
            content: attr(data-content);
            position: absolute;
            left: 0;
            top: 0;
            color: black;
            clip-path: inset(0 0 0 50px);
        }
    }
}

@media (min-width: 700.99px) {
    .projectdetail {
        &__inner-gallery {
            display: none;
        }

        &__share {
            display: flex;
        }
    }
}

@media (max-width: 700px) {
    .projectdetail {
        &__text1,
        &__text3 {
            margin: 2rem;
        }

        &__gallery {
            margin-left: auto;
            margin-right: auto;
            max-width: 323px;
            div.react-photo-gallery--gallery {
                display: none;
            }
        }

        .slick-slide {
            margin-right: 3px;
        }

        &__gallery-item {
            max-width: 300px;
            max-height: 300px;
        }

        &__inner-gallery {
            padding: 0 16% 0 0%;
        }

        &__share {
            display: none;
        }
    }
}

// @media (min-width: 501px) {
//     .projectdetail__inner-gallery {
//         padding: 0 50% 0 0%;
//     }
// }

@media (max-width: 500px) {
    .projectdetail {
        &__gallery {
            max-width: 360px;
        }

        &__text1,
        &__text3 {
            margin: 1.5rem;
        }
    }
}

@media (max-width: 400px) {
    .projectdetail {
        &__gallery {
            max-width: 340px;
        }
    }
}

@media (min-width: 460px) {
}
