#cursor {
    position: fixed;
    -webkit-transform: translateZ(0);
    top: -53px; ////position of hidden mouse
    left: -53px; //
    z-index: 99999;
    mix-blend-mode: difference;
    -webkit-filter: invert(1) grayscale(1);
    filter: invert(1) grayscale(1);
    pointer-events: none;
    svg {
        overflow: visible !important;
    }
    // display: none;
}

#cursor.active {
    display: block;

    svg {
        transition: width 0.05s linear;
        transition: height 0.05s linear;
        width: 76px;
        height: 76px;
    }
}

#cursor.active {
    display: block;

    .attracted {
        transition: transform 0.4s linear;
    }

    svg {
        transition: width 0.05s linear;
        transition: height 0.05s linear;
        width: 87px;
        height: 87px;
    }
}

.cursor-inner {
    // border: 1px solid red;
    width: 100px;
    height: 100px;
    position: fixed;
    top: 5px;
    left: 5px;
    pointer-events: none;
    z-index: 999;
    overflow: visible;
    transition: transform 0.07s linear;
    mix-blend-mode: difference;

    & > div {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    &__square {
        height: 100%;
        display: none;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        & > span {
            width: 6px;
            height: 6px;
            background: #000;
            display: block;
        }
    }

    &__nav-link,
    &__link {
        display: none;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        & > span {
            display: block;
            font-weight: bold;
            font-size: 1.5em;
        }
    }

    .next-project-cursor {
        transform: rotate(90deg);
    }

    &__link {
        span {
            transform: rotate(-45deg);
        }
    }
}

.cursor-inner--visible {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
}

.cursor-outline {
    // display: none;
    justify-content: center;
    align-items: center;
    mix-blend-mode: normal;
    font-weight: bold;

    svg {
        animation: attracted 0.25s linear;
    }
}

.cursor-inner--block {
    display: block;
}

.cursor-inner--none {
    display: none;
}

.cursor-close-btn {
    position: relative;
}

.cursor-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.cursor--visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
}

.cursor-inner__arrows {
    svg {
        width: 101.25px !important;
        height: 76.7px !important;
    }
}

@keyframes attracted {
    0% {
        width: 90px;
        height: 90px;
    }
    25% {
        width: 110px;
        height: 110px;
    }
    50% {
        width: 100px;
        height: 100px;
    }
    75% {
        width: 90px;
        height: 90px;
    }
}

@media (min-width: 992px) {
    #root * {
        cursor: none !important;
    }
}

@media (max-width: 991.99px) {
    #cursor,
    .cursor-inner__square,
    .cursor-inner__close,
    .cursor-inner__arrows,
    .cursor-inner__link,
    .cursor-inner__nav-link,
    .cursor-inner__arrows {
        display: none !important;
    }
}
