@import "../../../variables";

.interiors-detail {
    &__inner {
        position: relative;
    }

    &__hat {
        position: sticky;
        top: 0;
        z-index: -1;
    }

    &__hat-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.7;
    }

    &__hatimg {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
        position: absolute;
        top: 0;
        left: 0;
    }

    &__text {
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        background-color: black;
        color: white;
        display: flex;
        padding: 1em;
        padding-left: 10%;
        z-index: 50;
        margin-top: -2px;

        div:first-child {
            font-weight: bold;
            font-size: 20px;
            margin: 0 1em;
        }

        div:nth-child(2) {
            max-width: 15em;
            margin: 0 auto;
            font-size: 10px;
        }
    }

    &__cover-subtitle,
    &__cover-subtitle > span,
    &__cover-subtitle > p > span,
    &__cover-subtitle > p {
        background-color: transparent !important;
    }

    .site-path {
        margin-top: 30px;
        margin-left: 5%;
    }

    &__midsection {
        display: flex;
        margin-bottom: 2rem;
    }

    &__midsection-productname {
        & > div:first-child {
            margin-top: 0.5rem;
            font-size: 16px;
            font-weight: bold;
        }

        & > div:nth-child(2) {
            font-size: 14px;
            color: $faded-black;
        }
    }
    &__midsection-img {
        margin-top: 1rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__midsection-text {
        color: $faded-black;
        line-height: 1.5;
    }

    //GALLERY

    &__gallery {
        width: 100%;
        text-align: end;
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }

    &__inner-gallery {
        max-width: 550px;
        align-self: flex-end;
        width: 100%;
        overflow: hidden;
    }

    .slick-list {
        overflow: visible;
        margin: 0 auto;
    }

    .slick-track {
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .slick-active {
        margin: 0 auto;
        transition: opacity 400ms;
    }

    .slick-slide:not(.slick-active) {
        opacity: 0.2;
    }

    &__gallery-item {
        width: auto;
        margin-left: 0;
        margin-right: 5px;
        background-size: cover;
        background-position: center;
    }

    &__slides-num {
        margin-left: 0.5rem;
    }

    //GALLERY

    //WEB-GALLERY

    &__inner-gallery-web {
    }

    &__upper-gallery {
        height: 60%;
        display: flex;

        & > div:first-child {
            flex: 1 1 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        & > a:last-child {
            flex: 1 1 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &__lower-gallery {
        height: 40%;
    }

    &__prod1,
    &__prod2,
    &__prod3,
    &__prod4 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2rem;

        & > div:nth-child(2) {
            margin-top: 0.5rem;
            font-size: 16px;
            font-weight: bold;
            color: $black;
        }

        & > div:nth-child(3) {
            font-size: 14px;
            color: $faded-black;
        }
    }

    &__prod1 {
        justify-self: end;
    }

    &__img1,
    &__img2,
    &__img3,
    &__img4 {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__img1 {
        height: 600px;
        width: 350px;
    }

    &__img2 {
        height: 300px;
        width: 400px;
    }

    &__img3 {
        height: 320px;
        width: 450px;
    }

    &__img4 {
        height: 550px;
        width: 800px;
    }

    //END OF WEB-GALLERY
}

@media (min-width: 2500px) {
    .interiors-detail {
        background-image: linear-gradient(to bottom, transparent 80vh, #fff 100px, #fff 0);
        &__hat {
            height: 80vh;
        }
    }
}

@media (max-width: 2500px) {
    .interiors-detail {
        background-image: linear-gradient(to bottom, transparent 90vh, #fff 100px, #fff 0);
        &__hat {
            height: 90vh;
        }
    }
}

@media (min-width: 1024px) {
    .interiors-detail {
        &__text {
            padding-bottom: 2rem;

            div:first-child {
                font-size: 40px;
            }
            div:nth-child(2) {
                font-size: 16px;
            }
        }

        &__midsection {
            flex-direction: row;
            max-width: 1200px;
            margin: 100px auto 0 auto;
        }

        &__midsection-text {
            display: table;
            align-self: center;
            width: 35%;
            margin: 0 auto;
        }

        &__midsection-img {
            height: 583px;
            width: 583px;
        }

        &__inner-gallery {
            display: none;
        }

        &__inner-gallery-web {
            max-width: 1700px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

@media (max-width: 1024px) {
    .interiors-detail {
        background-image: linear-gradient(to bottom, transparent 45vh, #fff 100px, #fff 0);

        &__hat {
            height: 45vh;
        }
        &__text {
            div:first-child {
                font-size: 20px;
            }
            div:nth-child(2) {
                font-size: 10px;
            }
        }

        &__midsection {
            flex-direction: column;
        }

        &__midsection-img {
            height: 60vh;
        }

        &__midsection-productname {
            display: none;
        }

        &__midsection-text {
            font-size: 16px;
            padding-bottom: 1rem;
            display: table;
            max-width: 500px;
            margin: 2rem 1.5rem 1rem 1.5rem;
        }
    }
}

@media (min-width: 992.99px) {
    .interiors__detail {
        &__midsection {
            margin-top: 100px;
        }
    }
}

@media (min-width: 900px) {
    .interiors-detail {
        &__inner-gallery {
            display: none;
        }

        &__inner-gallery-web {
            overflow-x: hidden;
            display: block;
        }
    }
}

@media (max-width: 900px) {
    .interiors-detail {
        &__midsection {
            margin-bottom: 0;
        }

        &__midsection-img {
            height: 50vh;
        }

        //gallery
        &__gallery {
            padding-right: 0;
            padding-top: 0;
        }

        &__inner-gallery-web {
            display: none;
        }

        &__gallery-item {
            min-height: 300px;
        }

        &__inner-gallery {
            padding: 0 15% 0 0%;
        }

        &__slides-num {
            text-align: left;
            font-size: 14px;
            color: $faded-black;
        }
    }
}

@media (max-width: 700px) {
    .interiors-detail {
        &__midsection-img {
            height: 40vh;
        }
    }
}

@media (max-width: 500px) {
    .interiors-detail {
        &__midsection-img {
            height: 30vh;
        }
    }
}
