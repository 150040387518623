@import "../../variables";


.footer {
  background-color: black;
  color: white;
  bottom: 0;
  width: 100%;
}

.footer-inner {
  // border: 1px solid white;
  max-width: 1400px;
}
.wrapper {
  width: 269px;
  box-shadow: white;
}
.wrapper > p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1em;
}
.wrapper .input-data {
  height: 40px;
  width: 100%;
  position: relative;
  display: flex;
}
.wrapper .input-data input {
  background-color: black;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 14px;
  border-bottom: 2px solid white;
  display: flex;
  align-items: center;
  caret-color: white;
  color: white;
}
.input-data input:focus ~ label,
  .input-data input:hover ~ label,
  input.dirty ~ label
{
  transform: translateY(-25px);
  font-size: 14px;
  color: white;
}

.input-data input:focus {
  outline: none;
}
.wrapper .input-data label {
  position: absolute;
  bottom: 1px;
  left: 0;
  color: white;
  pointer-events: none;
  transition: all 0.3s ease;
}
.input-data .underline {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
}

.input-data span {
  align-self: center;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: white;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before
  // .input-data input:valid ~ .underline:before
{
  transform: scaleX(1);
}


.footer-email-button {     
    background: transparent !important;
    border: none !important;
    outline: none !important;
}

//CONTACT
.contact-container {
  
}
.contact {
  font-size: 14px;

  &__small-underline {
    width: 10px;
    height: 1px;
    background-color: $white;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}

.contact,
.email {
  line-height: normal;
  margin-bottom: 33px;
}

.media {
  line-height: 1.2;
  a {
    text-decoration: none;
    color: white;
    font-size: 14px;
  }

  a:focus,
  a:hover {
    text-decoration: underline;
  }
}

@media (min-width: 2000.99px) {
  .footer {
    display: flex;
    justify-content: center;
    padding-top: 45px;
  }

  .footer-inner {
    width: 1400px;
  }
}

@media (max-width: 2000px) {
  .footer {
    padding: 45px 10% 0 100px;
  }
}


@media (min-width: 1200.99px) {
  .contact-container {
    div.contact:first-child,
    div.contact:nth-child(2)  {
      margin-right: 50px;
    }
  }
}

@media (max-width: 1200px) {
  .contact-container {
    div.contact:first-child,
    div.contact:nth-child(2) {
      margin-right: 30px;
    }
  }
}

@media (min-width: 992px) {
  .footer {
    height: 186px;
  }

  .footer-inner {
    display: flex;
    // padding-right: 15%;
    // padding-left: 3em;
    justify-content: space-between;
  }

  .media {
    order: 2;
  }
  .contact-container {
    order: 3;
    display: flex;
  }
}

@media (max-width: 991.99px) {
  .footer {
    min-height: 515px;
    padding: 55px 50px;
  }
}

@media (max-width: 430px) {
  .footer {
    padding: 55px 35px;
  }
  
}

@media (max-width: 330px) {
  .wrapper {
    width: 200px;
  }
}

