@import "../../variables";

.banner-container {
    overflow-x: hidden;
}

.welcome {
    z-index: 150;
    position: relative;
    padding: 0;
    height: 100vh;
    background-size: cover;
    margin-bottom: 5em;
    overflow: hidden;

    .videowrapper {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: $light-grey;

        video {
            object-fit: cover;
        }
    }

    &__overlay-menu {
        position: absolute;
        top: 0;
        margin-top: -1px;
        width: 100%;
        height: 100px;

        .topbar {
            // z-index: 1000px;
            background: none;
            position: fixed;
            top: 0;
        }
    }

    .overlay {
        position: absolute;
        top: auto;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.15);

        &__text {
            position: absolute;
            width: auto;
            height: 20px;
            bottom: 5rem;
            right: -23px;
            margin-bottom: 30px;

            display: flex;
            color: white;
            transform: rotate(90deg);
        }

        &__text > div:first-child {
            width: 70px;
            height: 1px;
            background-color: white;
            align-self: center;
            justify-self: center;
            margin-right: 0.5rem;
            transition: opacity 0.3s;
        }

        &__text > div:last-child {
            align-self: center;
            transition: opacity 0.3s;
        }

        &__text:hover > div {
            opacity: 0.5;
        }
    }
}

.description-container {
    overflow-x: hidden;
    padding-bottom: 100px;
}

.description {
    display: flex;
    position: relative;
    margin: 0 auto;
    margin-bottom: 3em;

    &__overlay-text-wrapper {
        position: relative;
        // min-height: 100%
        margin-bottom: auto;
        margin-top: 2em;
    }

    &__img {
        max-width: 800px;
        display: flex;
        // align-items: center;

        

        h3 {
            position: relative;
            // top: 2em;
            color: white;
            margin-left: -70px;
        }
        

        h3:before {
            color: $black;
            clip-path: inset(0 0 0 70px);
        }
        h3:not(.description__duplicate):before {
            content: attr(data-content);
            position: absolute;
            left: 0;
            top: 0;
        }

        h3,
        h3:before {
            max-width: 500px;
        }
    }

    &__img-img {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__text {
        display: flex;
        color: $faded-black;
        flex-direction: column;
        justify-content: flex-end;
        line-height: normal;

        a {
            display: flex;
            text-decoration: none;
            color: $black;

            span {
                align-self: center;
                margin-left: 0.3em;
            }
        }

        a,
        a:hover,
        a:focus {
            text-decoration: none;
        }
    }

    &__text.description__text-web {
        // bottom: -30px;
    }

    &__text.description__text-web > a {
        align-self: flex-end;
        padding-bottom: 10px;
    }

    &__next-button {
        display: flex;
        justify-content: flex-end;
    }
}

.center {
    display: flex;
    justify-content: center;
}



@media (min-width: 992px) {
    .description {
        &__img {
            position: relative;
            margin-right: auto;
            margin-left: auto;

            h3,
            h3:before {
                font-size: 48px;
                line-height: 55px;
            }
        }

        &__img-img {
            height: 495px;
            width: 368px;
        }

        &__overlay-text-wrapper {
            width: calc(391px + 45px + 70px);
        }

        &__text {
            align-self: flex-end;
            right: 0;
margin: 50px 0 0 45px;
            font-size: 16px;
            line-height: 19.2px;
        }

        &__safari {
            max-width: 45% !important;
        }

        &__text.description__text-mobile {
            display: none;
        }
    }
}

@media (max-width: 991.99px) {
    .description {
        flex-direction: column;

        &__img-img {
            height: 288px;
            width: 191px;
        }

        &__img {
            h3,
            h3:before {
                font-size: 24px;
                max-width: 250px;
                line-height: 28px;
            }
        }

        &__overlay-text-wrapper {
            top: 3em;
            width: 170px;
        }

        &__text {
            margin-top: 30px;
            position: initial;
            bottom: unset;
            left: unset;
            font-size: 14px;
            
            div {
                width: 90%;
            max-width: 300px;
            }
        }

        &__text.description__text-web {
            display: none;
        }
    }
}


@media (max-width: 576px) {
    .description {
        margin-left: 20px;
        margin-right: 20px;
        &__img {
            max-width: 98%;
        }

        &__img {
            h3,
            h3:before {
                max-width: 250px;
            }
        }

        h3 {
            margin-left: -120px;
        }

        h3:before {
            clip-path: inset(0 0 0 120px);
        }

        

        &__text {
            position: initial;
            max-width: 90%;
        }
    }
}

@media (max-width: 350px) {
    .description {
        margin-left: 0;
        

        &__img {
            h3,
            h3:before {
                // width: 150px;
                font-size: 18px;
            }
        }
    }
}
