.living {
    position: relative;
    display: flex;
    flex-direction: column;
    // margin-top: 20px;

    &__hat {
        position: sticky;
        top: 0;
        z-index: -1;
    }

    &__img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
        position: absolute;
        top: 0;
        left: 0;
    }

    &__text {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        margin-top: -2px;
        background-color: black;
        border-top: 2px solid black;
        color: white;
        display: flex;
        padding-right: 1em;
        padding-left: 10%;
        padding-bottom: 40px;
        padding-top: 20px;
        z-index: 50;
        //transform: translateY(-1px);

        div:first-child {
            font-weight: bold;
            font-size: 20px;
            margin: 0 1em;
        }

        div:nth-child(2) {
            max-width: 15em;
            margin: 0 auto;
            font-size: 10px;
        }
    }
}



 @media(min-width: 2500.99px) {
    .living {
        background-image: linear-gradient(
            to bottom,
            transparent 80vh, 
            #fff 100px, 
            #fff 0
          );
        &__hat {
            height: 80vh;
        }

    }
}

@media(max-width: 2500px) {
    .living {
        background-image: linear-gradient(
            to bottom,
            transparent 90vh, 
            #fff 100px, 
            #fff 0
          );
    &__hat {
        height: 90vh;
    }
}
}

@media(min-width: 1024px) {
    .living {
        &__text {
        padding-bottom: 2rem;
        padding-bottom: 60px;
        padding-top: 30px;

            div:first-child {font-size: 40px;}
            div:nth-child(2) {font-size: 16px;}
        }
    }
}

@media(max-width: 1024px) {
    .living {
        background-image: linear-gradient(
            to bottom,
            transparent 35vh, 
            #fff 100px, 
            #fff 0
          );

        &__hat {
            height: 35vh;
        }
        &__text {
            div:first-child {font-size: 20px;}
            div:nth-child(2) {font-size: 10px;}
        }
    }
}
