@import "../../variables";

.projects {
    width: 100%;
    min-height: 90vh;
    overflow-x: hidden;

    &__inner {
    height: auto;
    padding: 7rem 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        line-height: 1.8;
    }


    &__story {
    color: $faded-black;
    align-self: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;
    }


    
    &__name {
        font-weight: bold;
        line-height: normal;
    }


    // .projects__site-path {
    //     margin-bottom: 2rem;
    // }

    &__gallery {
        margin-right: 20px;
        width: 100%;
        text-align: end;
        flex-direction: column;

    }

    &__inner-gallery {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .slick-list {
        overflow: visible;
        margin: 0 auto;
    }


.slick-track {
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    margin-top: 3em;
    margin-bottom: 3em;
}

.slick-active {
    margin: 0 auto;
    transition: opacity 400ms;
}

.slick-slide:not(.slick-active) {
    opacity: 0.2;
}



&__gallery-item {
    position: relative;
    // width: auto;
    margin-left: 0;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
}

&__overlay {
    position: absolute;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .2;
}

&__overlay-text {
    position: absolute;
    text-align: left;
    bottom: 1rem;
    left: 1rem;

    & > a > div:first-child {
        color: $white-smoke;
        opacity: .7;
        font-size: 16px;
        display: flex;

        span {
            display: inline-block;
            background-color: $white-smoke;
            width: 2px;
            height: 2px;
            align-self: center;
            margin: 0 .5rem;
        }
    }

    & > a > div:last-child {
        color: $white;
        font-size: 28px;
    }
}


&__slides-num {
    margin-left: .5rem;
}

.slider-next-button {
    margin-right: 150px;
}


}



// @media(min-width: 1201px) {
//     .projects__inner-gallery {
//         padding: 0 20% 0 0%;
//     }
// }

// @media(max-width: 1200px) {
    
// }


@media(min-width: 992.99px) {

    .projects {
        &__inner {
            flex-direction: row;
        }
        
    

    &__text {
        padding-top: 70px;
        margin-top: 50px;
        margin-left: 10px;
        min-width: 45%;
    }

    &__name {
        font-size: 60px;
    }

    &__story {
        font-size: 16px;
        max-width: 300px;
    }

    &__gallery {
        max-width: 80%;
    }

    &__inner-gallery {
        height: 100%;
        padding: 0 16% 50px 0; /////
    }

    &__gallery-item {
        min-height: 500px;
        min-width: 100px;
    }


    &__slides-num {
        display: none;
    }
}
}

@media(max-width: 992px) {
    .projects {

    &__inner {
        width: 80%;
    }
        
    

    &__services {
        font-size: 12px;
    }

    &__name {
        font-size: 30px;
    }

    &__story {
        font-size: 14px;
        max-width: 280px;
    }

    &__inner-gallery {
        padding: 0 20% 0 0%;
    }

    &__gallery-item {
        min-height: 300px;
    }


    &__slides-num {
        text-align: left;
        font-size: 14px;
        color: $faded-black;
    }
}

}


@media(max-width: 700px) {
    .projects__name {
        font-size: 24px;
    }
}

