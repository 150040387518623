@import "../../../variables";

.gallery {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;

  &__background {
    //position:
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $light-grey;
    display: none;
  }

  .slider-item-wrapper {
    position: relative;
  }

  .slider-item-overlay {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 500px;
    top: -100px;
    left: 0;
    margin-right: -2px;
    display: flex;
    justify-content: space-between;
  }

  div.text {
    margin-top: 0.6rem;
    margin-left: 0.4rem;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    div:first-child {
      font-size: 1rem;
      font-weight: bold;
    }

    div:nth-child(2) {
      font-size: 0.9rem;
      a,
      a:hover {
        text-decoration: none;
        color: $black;
      }
      span {
        display: inline-block;
        transform: rotate(-45deg);
      }
    }
  }

  .alice-carousel__stage > li:nth-of-type(even) > div > div {
    height: 230px;
    transform: translateY(145px);
  }

  .alice-carousel__stage > li:nth-of-type(odd) > div > div {
    height: 340px;
    transform: translateY(90px);
  }
}

.alice-carousel__stage-item {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.alice-carousel-item {
  background-size: cover;
  background-position: center;
  width: 230px;
}

.animated-path-1 {
  stroke-dasharray: 1000;
  animation: draw1 6s linear infinite;
}

.alice-carousel__wrapper {
  margin-top: 80px;
}

.alice-carousel {
  background: url(../../../assets/grid.png) center;
  background-repeat: repeat-x;
}

@keyframes draw1 {
  0% {
    stroke-dasharray: 400 540;
    stroke-dashoffset: -70;
  }
  50% {
    stroke-dasharray: 540 0;
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dasharray: 540 0;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 400 540;
    stroke-dashoffset: -70;
  }
}

.animated-path-2 {
  stroke-dasharray: 1000;
  /*   stroke-dashoffset: 800; */
  animation: draw2 6s linear infinite;
  animation-delay: 0.5s;
}

@keyframes draw2 {
  0% {
    stroke-dasharray: 560 0;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 400 560;
    stroke-dashoffset: -80;
  }
  60% {
    stroke-dasharray: 400 560;
    stroke-dashoffset: -80;
  }
  100% {
    stroke-dasharray: 560 0;
    stroke-dashoffset: 0;
  }
}

@media (max-width: 1450px) {
  .gallery {
    max-width: 1200px;
  }
}

@media (max-width: 1150px) {
  .gallery {
    max-width: 920px;
  }
}

@media (max-width: 900px) {
  .gallery {
    max-width: 650px;
  }
}

@media (max-width: 625px) {
  .gallery {
    max-width: 374px;
  }
}
